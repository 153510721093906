import React from "react"
import { graphql } from "gatsby"

import Master from "../layout/_master"
import SEO from "../components/seo"

const BlogPost = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  
  return (
    <Master>
      <SEO title={frontmatter.title} />
      <div>
        <a href="/blog/">{"< /blog"}</a>
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
    </Master>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`

export default BlogPost
